<template>
  <v-card min-width="300px" height="400px" flat class="rounded-lg">
    <v-card-title class="text-color-title caption font-weight-bold py-1">
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn icon @click="menuClick()">
        <v-icon>mdi-menu-open</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text class="card-content mt-4">
      <v-expansion-panels flat accordion>
        <v-expansion-panel v-for="(item, key) in items" :key="key">
          <v-expansion-panel-header>
            <span>
              <v-icon class="mr-2 text-no-wrap">{{ item.icon }}</v-icon>
              {{ item.name }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="transparent">
            <v-list dense>
              <v-list-item
                v-for="(link, linkKey) in item.links"
                :key="linkKey"
                @click="MIXINS_GO_TO_PAGE(link.link)"
              >
                <v-list-item-content class="text-no-wrap">
                  {{ link.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    title: "SHORTCUTS",
    items: [
      {
        name: "People",
        icon: "mdi-account-outline",
        links: [
          {
            name: "Profile",
            link: "/"
          },
          {
            name: "Notes",
            link: "/"
          },
          {
            name: "Directory",
            link: "/"
          },
          {
            name: "Individuals",
            link: "/"
          },
          {
            name: "Families",
            link: "/"
          },
          {
            name: "Significant Events",
            link: "/"
          }
        ]
      },
      {
        name: "Groups",
        icon: "mdi-account-group-outline",
        links: [
          {
            name: "Overview",
            link: "/"
          },
          {
            name: "Group Contact",
            link: "/"
          },
          {
            name: "Names",
            link: "/"
          },
          {
            name: "Birthdays",
            link: "/"
          },
          {
            name: "Anniversaries",
            link: "/"
          },
          {
            name: "Postal Code",
            link: "/"
          },
          {
            name: "Participants",
            link: "/"
          }
        ]
      },
      {
        name: "Daily",
        icon: "mdi-weather-sunny",
        links: [
          {
            name: "Devotional",
            link: "/devotionals"
          },
          {
            name: "Featured Video",
            link: "/featured-videos"
          },
          {
            name: "Worship Music",
            link: "/worships"
          },
          {
            name: "Calendar",
            link: "/"
          }
        ]
      },
      {
        name: "Prayer Requests",
        icon: "mdi-handshake",
        links: [
          {
            name: "Prayer Requests",
            link: "/"
          }
        ]
      },
      {
        name: "Attendance",
        icon: "mdi-calendar",
        links: [
          {
            name: "Events",
            link: "/"
          },
          {
            name: "Conferences",
            link: "/"
          }
        ]
      },
      {
        name: "Alerts & Notifications",
        icon: "mdi-exclamation-thick",
        links: [
          {
            name: "Messaging",
            link: "/"
          },
          {
            name: "Announcements",
            link: "/"
          }
        ]
      }
    ]
  }),
  methods: {
    // getData() {
    //   this.citation = {
    //     verse: "John 3:16",
    //     text:
    //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sodales interdum odio non mattis. Etiam placerat elit luctus arcu feugiat tempus. Nulla facilisi. Duis luctus neque vel pretium pretium. Sed."
    //   };
    // },
    menuClick() {
      // alert("click");
    }
  },
  mounted() {
    // this.getData();
  }
};
</script>

<style lang="scss" scoped>
.scrollable-list {
  max-height: 200px;
  overflow-y: auto;
}
.card-content {
  max-height: 300px;
  overflow-y: auto;
}
</style>
